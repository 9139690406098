import React, { useState } from 'react';
import styles from './Contact.module.css';
import instagram from '../../images/instagram-logo.svg';
import linkedin from '../../images/linkedin-logo.svg';
import copy from '../../images/copy.svg';
import copied from '../../images/copied.svg';

function Contact() {
    const emailadd = "lorcanbmedia@gmail.com";
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(emailadd)
          .then(() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 1000);
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      } else {
        fallbackCopyToClipboard(emailadd);
      }
    };
    
    const fallbackCopyToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
    
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
    
      textArea.focus();
      textArea.select();
    
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1000);
          console.log('Fallback: Copying text was successful');
        } else {
          console.error('Fallback: Could not copy text');
        }
      } catch (err) {
        console.error('Fallback: Error in copying text: ', err);
      }
    
      document.body.removeChild(textArea);
    };    

    return (
        <div id="contact" className="section">
            <h2 className="heading">Contact Me</h2>
            <p className="genericText">Ready to bring your vision to life? <br></br>Let’s collaborate and make something unforgettable.</p>
            <div className={styles.contactInfo}>
            <div className={styles.emailBox}>
              <a href={`mailto:${emailadd}`} className={styles.emailText}>{emailadd}</a>
              <img 
                src={isCopied ? copied : copy} 
                alt="Copy Email" 
                className={styles.copyIcon}
                onClick={copyToClipboard} 
              />
            </div>
            <div className={styles.socialMediaSection}>
              <a href="https://www.instagram.com/lorcanbmedia/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram" className={styles.socialMediaIcon} /></a>
              <a href="https://www.linkedin.com/in/lorcanbonner/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="LinkedIn" className={styles.socialMediaIcon} /></a>
            </div>
            </div>
      </div>
    );
}

export default Contact;